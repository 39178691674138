import React, { useEffect, useRef, useState } from "react"
import Layout from "../../components/shared-layout";
import { Alert, Autocomplete, Avatar, Box, InputBase, ListItem, ListItemText, FormControlLabel, CircularProgress, InputAdornment, Radio, RadioGroup, TextField, Typography, Checkbox, Grid, Accordion, AccordionSummary, AccordionDetails, Skeleton, IconButton, setRef, Paper } from "@mui/material";
import moment from "moment";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import SnowMoutain from "../images/snow-mountain.jpg";
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL } from "../../constants/style";
import VmButton from "../../components/shared-button";
import useStores from "../../hooks/use-stores"
import { GetLanguage, IsAuthed } from "../../utilities/general";
import * as queryString from "query-string";
import Geocode from "react-geocode";
import { googleMapApiKey } from "../../constants/settings";
import { GoogleMap, useJsApiLoader, DirectionsRenderer } from '@react-google-maps/api';
import { DefaultRoutingObj } from "../../utilities/travel-planning";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SouthIcon from '@mui/icons-material/South';
import RouteIcon from '@mui/icons-material/Route';
import { isWeb } from "../../utilities/platform";
import BackspaceIcon from '@mui/icons-material/Backspace';

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const TravelPlanningTripViewPage = observer(({ location }: { location: any }) => {
  const { bookingStore, rootStore } = useStores();
  const { t }: any = useTranslation();
  const [tripSummaryList, setTripSummaryList] = useState<any>([]);
  const [refresh, setRefresh] = useState<any>([]);
  const [routePlanning, setRoutePlanning] = useState<any>();
  const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: googleMapApiKey });

  useEffect(() => {
    setTripSummaryList([]);
    Geocode.setApiKey(googleMapApiKey);
    const tripSummary: any = isWeb && localStorage.TRIP_PLAN ? localStorage.TRIP_PLAN : null;
    if (tripSummary) {
      // @ts-ignore
      const summary = JSON.parse(tripSummary);
      setRoutePlanning(summary);
      console.log(summary);
      for (var day = 0; day < summary.day.length; day++) {
        onCaculateRouting(day, summary);
      }
    }
  }, []);

  const onCaculateRouting = (day: number, tripSummary: any) => {
    if (tripSummary.day[day].length > 1) {
      let arrangedCoords: any[] = [];
      for (var p of tripSummary.day[day]) {
        arrangedCoords.push({ ...DefaultRoutingObj, name: p.name, latitude: p.latitude, longtitude: p.longitude });
      }
      console.log(arrangedCoords, tripSummary.coords);
      bookingStore.runRoutePlanner(arrangedCoords)
        .then((planner: any) => startRouting(planner, day));
    }
  }

  const startRouting = (planner: any[], day: number) => {
    let wayPoints: any = [];
    let locationList: any = [];
    for (var key in planner) {
      wayPoints.push({ location: { lat: +planner[key][1], lng: +planner[key][2] } })
      locationList.push(planner[key][0]);
    }
    if (wayPoints.length > 0) {
      const origin = wayPoints.shift().location;
      const destination = wayPoints.pop().location;
      const directionsService = new google.maps.DirectionsService()
      directionsService.route({
        origin: origin,
        destination: destination,
        waypoints: wayPoints,
        travelMode: google.maps.TravelMode.DRIVING,
      }).then((results: any) => {
        tripSummaryList.push({ day, route: results.routes[0].legs, locations: locationList });

        tripSummaryList.sort((a: any, b: any) => a.day - b.day);
        setTripSummaryList(tripSummaryList);
        setRefresh([]);
      });
    }
  }

  const onExportSummary = () => {
    console.log(routePlanning, tripSummaryList);
    let req: any[] = [];
    tripSummaryList.map((trip: any, i: number) => {
      let tripObj: any = {};
      tripObj.totalDays = tripSummaryList.length;
      let routeList: any[] = [];
      trip.route.map((route: any, rIdx: number) => {
        let routeObj: any = {};
        routeObj.id = i;
        let location: string = trip.locations[rIdx];
        routeObj.coordinate = {
          location: location,
          lat: routePlanning ? routePlanning.place.find((p: any) => p.name === location).latitude : 0,
          lng: routePlanning ? routePlanning.place.find((p: any) => p.name === location).longitude : 0,
        }
        routeObj.startAddress = route.start_address;
        routeObj.endAddress = route.end_address;
        routeObj.duration = route.duration.text;
        routeObj.distance = route.distance.text;
        routeList.push(routeObj);
      })
      routeList.push({
        id: i,
        coordinate: {
          location: trip.locations[trip.route.length],
          lat: routePlanning ? routePlanning.place.find((p: any) => p.name === trip.locations[trip.route.length]).latitude : 0,
          lng: routePlanning ? routePlanning.place.find((p: any) => p.name === trip.locations[trip.route.length]).longitude : 0,
        },
        startAddress: trip.route[trip.route.length - 1].end_address,
        endAddress: trip.route[trip.route.length - 1].end_address,
        duration: 0,
        distance: 0,
      })
      tripObj.routes = routeList;
      req.push(tripObj);
    })
    console.log(req);
    bookingStore.getRoutingPDF(req);
  }

  return (
    <Layout pageName={t('ROUTE_SUMMARY')}>
      <Box className={PADDING_HORIZONTAL}>
        <p className="font-bold text-2xl 2xl:text-3xl mb-4">{t('YOUR_TRIP_SUMMARY')}</p>
        {bookingStore.routing ? <Box className="text-center">
          <CircularProgress />
          <p className="mt-4">{t('GENERATING_SUMMARY')}...</p>
        </Box>
          : <Box className="">

            <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
              <Paper sx={{ color: 'white', background: 'red' }}>
                <button className="p-4" onClick={() => window.close()}>
                  <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
                    <BackspaceIcon />
                    <p className="ml-2">{t('BACK_TO_PLANNING')}</p>
                  </Box>
                </button>
              </Paper>
              {tripSummaryList.length > 0 && <Paper sx={{ color: 'white', background: "#38bdf8", ml: 2 }}>
                <button className="p-4" onClick={onExportSummary}>
                  <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
                    <PictureAsPdfIcon />
                    <p className="ml-2">{t('EXPORT_AS_PDF')}</p>
                  </Box>
                </button>
              </Paper>}
            </Box>

            {tripSummaryList.length > 0 && tripSummaryList.map((trip: any, i: number) => (
              <Box key={`trip_${i}`} className="bg-white shadow-lg border p-2 mb-4">
                <p className="font-bold text-xl 2xl:text-2xl mb-2">{t('DAY_A')}{trip.day + 1}{t('DAY_B')}:</p>
                <table>
                  {bookingStore.routePlanner.length > 0 && trip.route && trip.route.map((route: any, idx: number) => (
                    <>
                      {idx == 0 && <tr>
                        <td className="w-1/3 text-right pr-3 pb-4">{trip.locations[idx]} <span className="text-white bg-red-500 rounded-full px-1">{alphabet[i]}</span></td>
                        <td className="w-2/3 pr-3 pb-4">
                          <b className="underline">{t('START')}:</b> <span className="no-underline">{route.start_address}</span> <span className="text-white bg-red-500 rounded-full px-1">{alphabet[i]}</span>
                        </td>
                      </tr>}
                      <tr>
                        <td className="w-1/3 text-right pb-4 pr-2"><SouthIcon /></td>
                        <td className="w-2/3 pr-3 pb-4 grid grid-cols-2 gap-2">
                          <Box>
                            <p className="font-bold underline">{t('TOTAL_DISTANCE')}:</p>
                            <Box sx={ITEM_PERFECT_INLINED}>
                              <span className="text-sky-500"><RouteIcon color="inherit" /></span>
                              <p className="ml-2">{route.distance.text}</p>
                            </Box>
                          </Box>
                          <Box>
                            <p className="font-bold underline">{t('DURATION')}:</p>
                            <Box sx={ITEM_PERFECT_INLINED}>
                              <span className="text-themeYellow"><AccessTimeIcon color="inherit" /></span>
                              <p className="ml-2">{route.duration.text}</p>
                            </Box>
                          </Box>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-1/3 text-right pr-3 pb-4"><p>{trip.locations[idx + 1]} <span className="text-white bg-red-500 rounded-full px-1">{alphabet[idx + 1]}</span></p></td>
                        <td className="w-2/3 pr-3 pb-4">
                          <b className="underline">{trip.route[i + 1] ? t('WAYPOINT') : t('END')}:</b> <span className="no-underline">{route.end_address}</span> <span className="text-white bg-red-500 rounded-full px-1">{alphabet[idx + 1]}</span>
                        </td>
                      </tr>
                    </>
                  ))}
                </table>
                {/* <Box key={`route_${i}`} className={`grid grid-cols-1 sm:grid-cols-3 gap-4`}>
                  <Box className="text-center sm:text-right border-b pb-4 sm:border-b-0 sm:pb-0 sm:border-r sm:pr-4">
                    {trip.locations && trip.locations.map((location: string, idx: number) => (
                      <>
                        <p className="mb-2" key={`dest_info_route_${i}`}>{location} <span className="text-white bg-red-500 rounded-full px-1">{alphabet[idx]}</span></p>
                        {trip.locations[idx + 1] && <Box className="mb-2"><SouthIcon /></Box>}
                      </>
                    ))}
                  </Box>
                  <Box className="sm:col-span-2">
                    {trip.route && trip.route.map((route: any, idx: number) => (
                      <>
                        {idx == 0 && <p>
                          <b className="underline">{t('START')}:</b> <span className="no-underline">{route.start_address}</span> <span className="text-white bg-red-500 rounded-full px-1">{alphabet[idx]}</span>
                        </p>}
                        <Box className="mt-2"><SouthIcon /></Box>
                        <Box className="grid grid-cols-2 gap-2 my-2">
                          <Box>
                            <p className="font-bold underline">{t('TOTAL_DISTANCE')}:</p>
                            <Box sx={ITEM_PERFECT_INLINED}>
                              <span className="text-sky-500"><RouteIcon color="inherit" /></span>
                              <p className="ml-2">{route.distance.text}</p>
                            </Box>
                          </Box>
                          <Box>
                            <p className="font-bold underline">{t('DURATION')}:</p>
                            <Box sx={ITEM_PERFECT_INLINED}>
                              <span className="text-themeYellow"><AccessTimeIcon color="inherit" /></span>
                              <p className="ml-2">{route.duration.text}</p>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="mb-2"><SouthIcon /></Box>
                        <p><b className="underline">{trip.route[idx + 1] ? t('WAYPOINT') : t('END')}:</b> <span className="no-underline">{route.end_address}</span> <span className="text-white bg-red-500 rounded-full px-1">{alphabet[idx + 1]}</span></p>
                      </>
                    ))}
                  </Box>
                </Box> */}
              </Box>
            ))}
          </Box>}
      </Box>
    </Layout>
  )
});


export default TravelPlanningTripViewPage;
